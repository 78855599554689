





































































































































































.formHeight{
  line-height: 15px;
  height: 500px;
}
.forminputHeight{

  height: 150px;
}
.noticeTitle {
  padding: 0px 10px;
  margin: 5px 0px;
  font-weight: 700;
  font-size: 15px;
  text-align: center;
}


.smallContent {
  font-size: 14px;
  img {
    margin: 20px;
    width: 40vw;
    align: center;
    font-size: 0;
  }
}

