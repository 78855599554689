































































































































































































































































































.editContentCtn{
  // 不设置字体的话 空格的宽度会很宽
  font-family: 'Avenir', 'Helvetica', 'Arial', 'sans-serif';
  position: relative;
  padding: 0 8px;
  border-radius: 2px;
  border: 1px solid #d0d0d0;
  line-height: 32px;
  font-size: 14px;
  // 得加上这个 不然点击下拉li的时候 有bug
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;

  .selectCtnBgCover{
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 0;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2
  }
  #messageDesc {
    outline: none!important;

    .keyWord{
      color: #FF6B07;
    }
  }
}
